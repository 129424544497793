<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
            >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
          >Excel</vs-button
          >
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="customer_code">Customer Code</vs-th>
        <vs-th sort-key="customer_name">Customer Name</vs-th>
        <vs-th sort-key="assignment">Assignment</vs-th>
        <vs-th sort-key="document_number">Document Number</vs-th>
        <vs-th sort-key="document_type">Document Type</vs-th>
        <vs-th sort-key="document_date">Document Date</vs-th>
        <vs-th sort-key="posting_date">Posting Date</vs-th>
        <vs-th sort-key="dn_cn_value">DN/CN Value</vs-th>
        <vs-th sort-key="payment">Payment</vs-th>
        <vs-th sort-key="dn_cn_balance">DN/CN Balance</vs-th>
        <vs-th sort-key="local_currency">Local Currency</vs-th>
        <vs-th sort-key="clearing_document">Clearing Document</vs-th>
        <vs-th sort-key="clearing_date">Clearing Date</vs-th>
        <vs-th sort-key="text">Text</vs-th>
        <vs-th sort-key="g_l_account">G/L Account</vs-th>
        <vs-th sort-key="reference">Reference</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].supplier_code">
            {{ data[indextr].supplier_code }}
          </vs-td>
          <vs-td :data="data[indextr].supplier_name">
            {{ data[indextr].supplier_name }}
          </vs-td>
          <vs-td :data="data[indextr].assignment">
            {{ data[indextr].assignment }}
          </vs-td>
          <vs-td :data="data[indextr].document_number">
            {{ data[indextr].document_number }}
          </vs-td>
          <vs-td :data="data[indextr].document_type">
            {{ data[indextr].document_type }}
          </vs-td>
          <vs-td :data="data[indextr].document_date">
            {{ dateFormat(data[indextr].document_date) }}
          </vs-td>
          <vs-td :data="data[indextr].posting_date">
            {{ dateFormat(data[indextr].posting_date) }}
          </vs-td>
          <vs-td :data="data[indextr].dn_value">
            {{ dateFormat(data[indextr].dn_value) }}
          </vs-td>
          <vs-td :data="data[indextr].payment">
            {{ formatPrice(data[indextr].payment) }}
          </vs-td>
          <vs-td :data="data[indextr].balance">
            {{ formatPrice(data[indextr].balance) }}
          </vs-td>
          <vs-td :data="data[indextr].currency">
            {{ formatPrice(data[indextr].currency) }}
          </vs-td>
          <vs-td :data="data[indextr].clearing_doc">
            {{ formatPrice(data[indextr].clearing_doc) }}
          </vs-td>
          <vs-td :data="data[indextr].clearing_date">
            {{ formatPrice(data[indextr].clearing_date) }}
          </vs-td>
          <vs-td :data="data[indextr].text">
            {{ data[indextr].text }}
          </vs-td>
          <vs-td :data="data[indextr].gl_account">
            {{ data[indextr].gl_account }}
          </vs-td>
          <vs-td :data="data[indextr].reference">
            {{ data[indextr].reference }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: ["baseUrl", "territoryIDs", "supplier", "startPostingDate", "endPostingDate", "draw", "openKeyDate", "territoryNames"],
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState()
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        supplier_ids: ""
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData(page = 1) {
      if (this.draw > 0) {
        if (
          this.territories == null ||
          this.territories == "" ||
          this.territories == [] ||
          this.territories == undefined
        ) {
          return true;
        }

        this.isActive = page;
        this.$vs.loading();

        let customer_ids =
          this.$store.getters["customerFilter/getSelectedCutomerIDs"];
        if (customer_ids.includes(0)) {
          customer_ids = [];
        }

        let territory_ids =
          this.$store.getters["operatingUnit/getSelectedTerritoryId"];

        this.$http
          .get(this.baseUrl + "/table", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              status: this.status,
              territory_ids: territory_ids,
              customer_ids: customer_ids,
              start_posting_date: this.startPostingDate
                ? moment(this.startPostingDate).format("YYYY-MM-DD")
                : null,
              end_posting_date: this.endPostingDate
                ? moment(this.endPostingDate).format("YYYY-MM-DD")
                : null,
              open_key_date: this.openKeyDate
                ? moment(this.openKeyDate).format("YYYY-MM-DD")
                : null
            },
          })
          .then(resp => {
            if (resp.status == "success") {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    handleExport(file) {
      
      this.setIDSupplier();
      this.$vs.loading();
      var fileTitle =
        "FBL5N_REPORT_" +
        (this.territoryNames.length > 0
          ? this.territoryNames.join("-")
          : "all") +
        "_";

      if (this.startPostingDate || this.endPostingDate) {
        "POSTING_DATE_" +
        moment(this.startPostingDate).format("YYYY-MM-DD") +
        "_-_" +
        moment(this.endPostingDate).format("YYYY-MM-DD") +
        "_";
      }

      fileTitle +=
        "OPEN_KEY_DATE_" + moment(this.openKeyDate).format("YYYY-MM-DD");

      console.log("this.territoryIDs", this.territoryIDs);
      console.log("this.supplier_ids", this.supplier_ids);
      
      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            territory_ids: this.territoryIDs.length == 0 ? 0 : this.territoryIDs,
            supplier_id: this.supplier_ids,
            start_posting_date: this.startPostingDate
              ? moment(this.startPostingDate).format("YYYY-MM-DD")
              : null,
            end_posting_date: this.endPostingDate
              ? moment(this.endPostingDate).format("YYYY-MM-DD")
              : null,
            open_key_date: this.openKeyDate
              ? moment(this.openKeyDate).format("YYYY-MM-DD")
              : null,
            file: file,
            title: fileTitle
          }
        })
        .then(resp => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        });
    },
    setIDSupplier() {
        let datas = this.supplier
        let keys = Object.keys(datas)
        let count = datas.length
        let dataIDS = ""

        keys.forEach(function (i) {
            if (datas[i].ID != 0) {
                dataIDS += datas[i].ID
                if ((parseInt(i) + 1) != count) {
                    dataIDS += ","
                }
            }
        });
        if(dataIDS === "undefined"){
          dataIDS = ""
        }
        this.supplier_ids = dataIDS
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    }
  },
  watch: {
    draw() {
      this.getData();
    }
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      }
    }
  }
};
</script>
